import CryptoJS from "crypto-js"

export default {
  // 加密
  encrypt(word, keyStr) {
    const key = CryptoJS.enc.Utf8.parse(keyStr)
    // const src = CryptoJS.enc.Utf8.parse(word)
    let encoded = CryptoJS.AES.encrypt(word, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    let words = encoded.ciphertext
    return CryptoJS.enc.Base64.stringify(words)
  },
  // 解密
  decrypt(word, keyStr) {
    const key = CryptoJS.enc.Utf8.parse(keyStr)
    // let base64 = CryptoJS.enc.Base64.parse(word)
    // let src = CryptoJS.enc.Base64.stringify(base64)
    let decoded = CryptoJS.AES.decrypt(word, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decoded).toString()
  }
};
<template>
  <div
    class="info-container"
    :class="!isMobile ? 'card-container': ''"
  >
    <div class="title-container">
      <h2>发布文章</h2>
    </div>
    <div class="content-container">
      <el-form
        :ref="formName"
        :model="query"
        :rules="queryRule"
        label-width="104px"
        :label-position="isMobile ? 'top' : 'left'"
      >
        <!-- 标题 -->
        <el-form-item
          label="标题"
          prop="title"
          required
        >
          <el-input
            type="text"
            minlength="8"
            maxlength="30"
            show-word-limit
            v-model="query.title"
            placeholder="请填寫至少8字"
          />
        </el-form-item>
        <!-- 来源 -->
        <el-form-item
          label="来源"
          prop="source"
          required
        >
          <el-input
            type="text"
            minlength="1"
            maxlength="30"
            show-word-limit
            v-model="query.source"
            placeholder="请填寫来源"
          />
        </el-form-item>
        <!-- 内文 -->
        <el-form-item
          label="内文"
          prop="content"
          ref="postArticleEditor"
          required
        >
          <tinymce-editor
            v-model="query.content"
            type="user_article"
            :id="articleId !== '' ? articleId : query.random_dirname"
            @changeContent="changeContent"
          />
        </el-form-item>
        <!-- 封面图片 -->
        <el-form-item
          label="封面图片"
          prop="upload"
          ref="uploadArticlePhoto"
        >
          <template>
            <div class="upload-colum">
              <div
                class="upload-block"
                @mouseenter="showHandleCover"
                @mouseleave="hideHandleCover"
              >
                <el-upload
                  class="cover-upload"
                  action=""
                  :show-file-list="false"
                  :on-change="handleUpload"
                  accept="image/jpg,image/png,image/jpeg"
                  :auto-upload="false"
                >
                  <img
                    v-if="imageUrl"
                    :src="imageUrl"
                    class="cover-photo"
                  >
                  <div
                    v-else
                    class="upload-icon"
                  >
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-upload>
                <div
                  v-if="imageUrl && showCover"
                  class="remove-upload"
                  @click="removeUpload"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
              <div
                slot="tip"
                class="upload-tip"
              >支持 jpg, png, jpeg 格式，尺寸135*110</div>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <div class="btn-container">
        <button
          class="btn btn-save"
          @click="handleBtnPost()"
        >
          发布
        </button>
        <button
          class="btn btn-cancel"
          @click="handleBtnCancel()"
        >
          取消
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { forIn, has } from 'lodash'
import { isMobile } from '@/utils/device'
import resStatus from '@/constants/resStatus'
import { goPage } from '@/utils/route'
import { getAESKey, postArticle, getArticle, editArticle } from '@/api/client/articles'
import TinymceEditor from '@/components/admin/tinymce-editor.vue'
import cryptoJS from '@/utils/crypto'

export default {
  components: {
    TinymceEditor
  },
  data() {
    return {
      articleId: '',
      formName: 'addForm',
      imageUrl: '',
      showCover: false,
      aesKey: '',
      query: {
        title: '',
        source: '',
        content: '',
        upload: null,
        random_dirname: ''
      },
      queryRule: {
        title: [
          {
            required: true,
            message: '请输入标题',
            // trigger: 'blur'
          },
          {
            min: 8,
            max: 30,
            message: '请输入至少8个字，最多30字',
            // trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入内文',
            // trigger: 'blur'
          }
        ],
        source: [
          {
            required: true,
            message: '请输入来源',
            // trigger: 'blur'
          }
        ],
        // upload: [
        //   {
        //     required: true,
        //     message: '请上传图片',
        //     // trigger: 'blur'
        //   }
        // ]
      }
    }
  },
  created () {
    const aid = this.$route.query.id
    if (!aid) {
      this.query.random_dirname = 'rand' + Math.floor(Math.random() * 100000)
    } else {
      this.articleId = aid
      this.handleDetail()
    }
  },
  computed: {
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    handleDetail () {
      getArticle(this.articleId)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.setFormData(res.data)
              break
            default:
              const msg = res.message !== '' ? res.message : '发生错误'
              this.$message.error(msg)
              this.$router.go(-1)
          }
        })
    },
    setFormData (data) {
      forIn(data, (val, key) => {
        if (has(this.query, key)) {
          this.query[key] = val
        }
      })
      tinymce.activeEditor.setContent(this.query.content)
      this.imageUrl = data.photo
    },
    removeUpload () {
      this.imageUrl = ''
      this.query.upload = null
      this.$refs.uploadArticlePhoto.validate()
    },
    handleUpload (file) {
      this.varifyPhotoMeasure(file).then(obj => {
        if (obj) {
          if (obj.message) {
            this.$message.error(obj.message)
            // this.$refs.uploadArticlePhoto.clearFiles()
          }
          if (obj.file) {
            this.query.upload = file.raw
            this.imageUrl = URL.createObjectURL(file.raw)
          }
        } else {
          this.$message.error('发生错误')
        }
      })
    },
    varifyPhotoMeasure (file) {
      // const filePath = file.url
      var width = 0
      var height = 0
      const that = this
      if (file.status) {
        const image = new Image()
        image.src = window.URL.createObjectURL(file.raw)
        return new Promise(resolve => {
          image.onload = function () {
            width = this.width
            height = this.height
            let resObj = {}
            if (width !== 135 && height !== 110) {
              resObj = { message: '图片尺寸应为：135*110' }
            } else {
              resObj = { file: file }
            }
            resolve(resObj)
            that.$refs.uploadArticlePhoto.clearValidate()
          }
        })
      } else {
        return { message: '上传失敗' }
      }
    },
    showHandleCover () {
      this.showCover = true
    },
    hideHandleCover () {
      this.showCover = false
    },
    changeContent (content) {
      if (content && content !== '') {
        this.$refs.postArticleEditor.clearValidate()
      }
    },
    doEncrypt(data, key) {
      return cryptoJS.encrypt(data, key)
    },
    handleBtnPost () {
      this.$refs[this.formName].validate(valid => {
        if (valid) {
          getAESKey()
            .then(res => {
              if (res.status === resStatus.OK) {
                this.aesKey = res.data.aes_key
                const ori_content = this.query.content
                this.query.content = this.doEncrypt(ori_content, this.aesKey)
                // let decoded = cryptoJS.decrypt(this.query.content, this.aesKey)
                this.submitArticle()
              }
            })
        }
      })
    },
    handleBtnCancel () {
      if (this.isMobile) {
        this.$router.go(-1)
      } else {
        goPage({ name: 'MemberPostManage' })
      }
    },
    submitArticle () {
      const saveFunc = this.articleId ? editArticle : postArticle
      saveFunc(this.query, this.articleId)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.$message.success('发布成功')
              goPage({ name: 'MemberPostManage' })
              break
            default:
              const msg = res.message !== '' ? res.message : '发生错误，请稍后再试'
              this.$message.error(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.info-container {
  background-color: #fff;
  .title-container {
    padding: 18px 30px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    h2 {
      font-weight: 500;
      font-size: 21px;
      color: #000000;
    }
  }
  .content-container {
    text-align: left;
    padding: 25px 30px;
    .upload-colum {
      .upload-block {
        width: 135px;
        height: 110px;
      }
    }
    .btn-container {
      display: flex;
      align-items: center;
      margin: 40px 0 5px 104px;
      .btn {
        width: 98px;
        margin-right: 15px;
        line-height: 23px;
        padding: 7px 0;
      }
      .btn-save {
        background-color: #005fde;
        color: #ffffff;
      }
      .btn-cancel {
        background: #ffffff;
        color: #005fde;
        border: 1px solid #005fde;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .info-container {
    background-color: #fff;
    .title-container {
      padding: 18px 30px;
      text-align: center;
      border-bottom: none;
      background: #56ccf2;
      h2 {
        color: #ffffff;
      }
    }
    .title-container {
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
      h2 {
        color: #ffffff;
      }
    }
    .content-container {
      text-align: left;
      padding: 25px 30px;
      .upload-colum {
        .upload-block {
          width: 135px;
          height: 110px;
        }
      }
      .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px 0 20px;
        .btn {
          width: 98px;
          margin-right: 15px;
          line-height: 23px;
          padding: 7px 0;
        }
        .btn-save {
          background-color: #005fde;
          color: #ffffff;
        }
        .btn-cancel {
          background: #ffffff;
          color: #005fde;
          border: 1px solid #005fde;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .info-container {
    background-color: #fff;
    .title-container {
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
    }
    .content-container {
      text-align: left;
      padding: 25px 30px;
      .upload-colum {
        .upload-block {
          width: 135px;
          height: 110px;
        }
      }
      .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px 0 20px;
        .btn {
          width: 98px;
          margin-right: 15px;
          line-height: 23px;
          padding: 7px 0;
        }
        .btn-save {
          background-color: #005fde;
          color: #ffffff;
        }
        .btn-cancel {
          background: #ffffff;
          color: #005fde;
          border: 1px solid #005fde;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info-container {
    background-color: #fff;
    .title-container {
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
      h2 {
        color: #000000;
      }
    }
    .content-container {
      text-align: left;
      padding: 25px 30px;
      .upload-colum {
        .upload-block {
          width: 135px;
          height: 110px;
        }
      }
      .btn-container {
        display: flex;
        align-items: center;
        margin: 40px 0 5px 104px;
        .btn {
          width: 98px;
          margin-right: 15px;
          line-height: 23px;
          padding: 7px 0;
        }
        .btn-save {
          background-color: #005fde;
          color: #ffffff;
        }
        .btn-cancel {
          background: #ffffff;
          color: #005fde;
          border: 1px solid #005fde;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
}
</style>
<style lang="scss">
.el-form--label-left,
.el-form--label-top {
  .el-form-item {
    margin-bottom: 35px;
    &:first-child {
      margin-bottom: 25px;
    }
  }
  .el-form-item__label {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
}

.el-form--label-top {
  .el-form-item {
    margin-bottom: 20px;
  }
  .el-form-item__label {
    padding: 0;
  }
}

.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  position: absolute;
  top: 20px;
  left: -14px;
  font-size: 28px;
  font-weight: 100;
  line-height: 0;
}
.el-form-item.is-error {
  .cover-upload,
  .tox-tinymce {
    border: 1px solid #f56c6c;
  }
}
.cover-upload {
  width: 123px;
  height: 100px;
  background: #f2f2f2;
  border: none;
  border-radius: 4px;
  line-height: 100px;
  text-align: center;
  overflow: hidden;
  &:hover {
    background: #f5f5f5;
    .upload-icon {
      i {
        color: #a5a5a5;
      }
    }
  }
  .cover-photo {
    width: 100%;
    vertical-align: middle;
  }
  .el-upload {
    width: 100%;
    .upload-icon {
      i {
        font-size: 32px;
        color: #9b9b9b;
        vertical-align: middle;
        &::after {
          content: "添加图片";
          display: block;
          font-size: 12px;
          margin-top: 5px;
        }
      }
    }
  }
}
.remove-upload {
  position: absolute;
  width: 123px;
  height: 100px;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 100px;
  border-radius: 4px;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 100;
  cursor: pointer;
  i {
    font-size: 32px;
    color: #ffffff;
    vertical-align: middle;
  }
}
.upload-tip {
  color: #828282;
  line-height: 16.8px;
  margin-top: 10px;
}
</style>
